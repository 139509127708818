<template>
<Page class="team" title="Resultat och matcher för">
      <Loader v-if="loading" loadingtext="Hämtar matcher..."></Loader>
      <div v-else>
            <div class="team-picker d-flex justify-content-center align-items-center">
                  <div>
                        <TeamSelect v-on:selected="SelectFirstTeam" :id="tid"></TeamSelect>
                  </div>
                  <div>
                        mot
                  </div>
                  <div>
                        <TeamSelect v-on:selected="SelectSecondTeam" :id="vid" showclear></TeamSelect>
                  </div>
            </div>
            <b-container fluid="lg" v-if="hasgames">
                  <TeamTournamentRow v-for="(tgames, index) in games()" :key="index" :games="tgames"></TeamTournamentRow>
            </b-container>
            <b-container fluid="lg" v-else>
                  <Alert variant="info" >Inga matcher hittades</Alert>
            </b-container>
      </div>
</Page>
</template>

<script>
const Page = () => import("@/components/common/Page");
const Alert = () => import("@/components/miscellaneous/Alert");
const Loader = () => import("@/components/miscellaneous/Loader");
const TeamSelect = () => import("@/components/miscellaneous/TeamSelect");
const TeamTournamentRow = () => import("@/components/team/TeamTournamentRow");

export default {
      name: "Team",
      components: {
            Page,
            Alert,
            Loader,
            TeamSelect,
            TeamTournamentRow
      },
      data() {
            return {
                  tid: false,
                  vid: false
            };
      },
      computed: {
            loading() {
                  return this.$store.getters["teams/teamsloading"] || this.$store.getters["games/teamgamesloading"];
            },
            hasgames() {
                  var g = this.$store.getters["games/teamgames"];
                  if (g && g.length > 0)
                        return true;
                  return false;
            }
      },
      methods: {
            games() {
                  var games = [];
                  var tgames = [];
                  var currentT = false;
                  var allgames = this.$store.getters["games/teamgames"];
                  for (var i = 0; i < allgames.length; i++) {
                        var ngame = allgames[i];
                        if (currentT && currentT != ngame.tournament.uuid) {
                              games.push(tgames);
                              tgames = [];
                        }

                        tgames.push(ngame);
                        currentT = ngame.tournament.uuid;
                  }

                  games.push(tgames);

                  return games;
            },
            GetTeam(id) {
                  return this.$functions.getTeam(id);
            },
            flag(id) {
                  var t = this.GetTeam(id);
                  return this.$images.teamFlag(t.flag);
            },
            GoTo(id) {
                  var t = this.GetTeam(id);
                  var url = this.$url.teamurl(t);
                  this.$router.push(url);
            },
            FixParams() {
                  this.tid = false;
                  this.vid = false;
                  var params = this.$route.params.pathMatch;
                  var parts = params.split("/");
                  if (parts.length > 0) {
                        this.tid = this._getParamId(parts[0]);
                        if (parts.length > 1) {
                              this.vid = this._getParamId(parts[1]);
                        }
                        this.GetData();
                  }
            },
            _getParamId(part) {
                  return part.split("-")[0];
            },
            GetData() {
                  if (this.tid) {
                        var post = {
                              tid: this.tid
                        };
                        if (this.vid)
                              post["vid"] = this.vid;
                        this.$store.dispatch("games/getTeamGames", post);
                  }
            },
            SelectFirstTeam(team) {
                  this.GoTo(team.id);
            },
            SelectSecondTeam(team) {
                  if (team) {
                        var t1 = this.GetTeam(this.tid);
                        var url = this.$url.teamvsurl(t1, team);
                        this.$router.push(url);
                  } else this.GoTo(this.tid);
            }
      },
      watch: {
            $route() {
                  this.FixParams();
            }
      },
      mounted() {
            this.FixParams();
      }
};
</script>
